<template>
  <c-col lg="6" md="6" class="mb-3">
    <div class="single__content__wrapper">
      <c-row>
        <c-col xs="12" lg="4" md="12">
          <div class="single__profile__avatar">
            <div class="profile__avatar">
              <img v-if="imgPath != null" :src="`${imgPath}`" alt="profile-1" class="img-fluid" />
              <img v-else src="../assets/AvatarDemo.png" alt="profile-1" class="img-fluid" />
            </div>
          </div>
        </c-col>
        <c-col xs="12" lg="8" md="12">
          <div class="profile__content__wrapper">
            <div class="profile__content__list">
              <ul>
                <li>
                  <c-row>
                    <div class="col-5 col-lg-4">
                      <div class="inner__title__label">
                        <label>Name :</label>
                      </div>
                    </div>
                    <c-col xs="7" lg="8" class="ps-0">
                      <div class="inner__title__desc">
                        <label>{{ name }}</label>
                      </div>
                    </c-col>
                  </c-row>
                </li>
                <li>
                  <c-row>
                    <c-col xs="5" lg="4">
                      <div class="inner__title__label">
                        <label>S/D O :</label>
                      </div>
                    </c-col>
                    <c-col xs="7" lg="8" class="ps-0">
                      <div class="inner__title__desc">
                        <label>{{ fatherName }}</label>
                      </div>
                    </c-col>
                  </c-row>
                </li>
                <li>
                  <c-row>
                    <c-col xs="5" lg="4">
                      <div class="inner__title__label">
                        <label>StudentID :</label>
                      </div>
                    </c-col>
                    <c-col xs="7" lg="8" class="ps-0">
                      <div class="inner__title__desc">
                        <label>{{ StudentID }}</label>
                      </div>
                    </c-col>
                  </c-row>
                </li>
                <li>
                  <c-row>
                    <c-col xs="5" lg="4">
                      <div class="inner__title__label">
                        <label>Adm/Reg# :</label>
                      </div>
                    </c-col>
                    <c-col xs="7" lg="8" class="ps-0">
                      <div class="inner__title__desc">
                        <label>{{ regNumber }}</label>
                      </div>
                    </c-col>
                  </c-row>
                </li>
                <li>
                  <c-row>
                    <c-col xs="5" lg="4">
                      <div class="inner__title__label">
                        <label>Class :</label>
                      </div>
                    </c-col>
                    <c-col xs="7" lg="8" class="ps-0">
                      <div class="inner__title__desc">
                        <label>{{ className }}</label>
                      </div>
                    </c-col>
                  </c-row>
                </li>
              </ul>
            </div>
          </div>
          <div class="single__profile__detail_btn text-center">
            <router-link :to="{ name: 'dashboard', params: { id: StudentID } }" class="custom__btn">View
              Details</router-link>
          </div>
        </c-col>
      </c-row>
    </div>
  </c-col>
</template>



<script>
import { CRow, CCol } from "@coreui/vue";
export default {
  name: "studentList",
  setup() {
    return {
    };
  },
  props: {
    StudentID: {
      type: Number,
    },
    name: {
      type: String,
    },
    fatherName: {
      type: String,
    },
    regNumber: {
      type: String,
    },
    className: {
      type: String,
    },
    imgPath: {
      type: String,
    },
  },
  components: {
    CRow,
    CCol,
  },
  data() {
    return {
      myData: [],
      loading: false,
    };
  },
};
</script>