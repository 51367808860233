<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Notifications</li>
        </ol>
      </nav>
    </div>
    <div class="notifications__main__menu__wrapper mt-4 mb-4">
      <div class="nav-scroller">
        <nav class="nav" aria-label="Secondary navigation">
          <router-link
            class="nav-link active"
            aria-current="page"
            :to="{ name: 'datesheet' }"
          >
            <div class="notifications__inner__menu__wrapper">
              <div class="notifications__inner__menu__img">
                <img
                  src="../../assets/notification-icons/datesheet.png"
                  alt="datesheet"
                />
              </div>
              <div class="notifications__inner__menu__label">
                <label class="d-block"
                  >DateSheet <span class="num__of__notification">{{ datesheet_num.length  }}</span></label
                >
                <p class="mb-0">Notifications</p>
              </div>
            </div>
          </router-link>
          <router-link
            class="nav-link"
            aria-current="page"
            :to="{ name: 'syllabus' }"
          >
            <div class="notifications__inner__menu__wrapper">
              <div class="notifications__inner__menu__img">
                <img
                  src="../../assets/notification-icons/syllabus.png"
                  alt="syllabus"
                />
              </div>
              <div class="notifications__inner__menu__label">
                <label class="d-block"
                  >Syllabus <span class="num__of__notification">{{ syllabus_num.length }}</span></label>
                <p class="mb-0">Notifications</p>
              </div>
            </div>
          </router-link>
          <router-link
            class="nav-link"
            aria-current="page"
            :to="{ name: 'holidays' }"
          >
            <div class="notifications__inner__menu__wrapper">
              <div class="notifications__inner__menu__img">
                <img
                  src="../../assets/notification-icons/holidays.png"
                  alt="holidays"
                />
              </div>
              <div class="notifications__inner__menu__label">
                <label class="d-block"
                  >Holidays <span class="num__of__notification">{{ holidays_num.length }}</span></label>
                <p class="mb-0">Notifications</p>
              </div>
            </div>
          </router-link>
          <router-link
            class="nav-link"
            aria-current="page"
            :to="{ name: 'general' }"
          >
            <div class="notifications__inner__menu__wrapper">
              <div class="notifications__inner__menu__img">
                <img
                  src="../../assets/notification-icons/general.png"
                  alt="general"
                />
              </div>
              <div class="notifications__inner__menu__label">
                <label class="d-block"
                  >General <span class="num__of__notification">{{ general_num.length }}</span></label>
                <p class="mb-0">Notifications</p>
              </div>
            </div>
          </router-link>
          <router-link
            class="nav-link"
            aria-current="page"
            :to="{ name: 'misconduct' }"
          >
            <div class="notifications__inner__menu__wrapper">
              <div class="notifications__inner__menu__img">
                <img
                  src="../../assets/notification-icons/misconduct.png"
                  alt="misconduct"
                />
              </div>
              <div class="notifications__inner__menu__label">
                <label class="d-block"
                  >Misconduct <span class="num__of__notification">{{ misconduct_num.length }}</span></label>
                <p class="mb-0">Notifications</p>
              </div>
            </div>
          </router-link>
          <router-link
            class="nav-link"
            aria-current="page"
            :to="{ name: 'sports' }"
          >
            <div class="notifications__inner__menu__wrapper">
              <div class="notifications__inner__menu__img">
                <img
                  src="../../assets/notification-icons/sports.png"
                  alt="sports"
                />
              </div>
              <div class="notifications__inner__menu__label">
                <label class="d-block"
                  >Sports <span class="num__of__notification">{{ sports_num.length }}</span></label>
                <p class="mb-0">Notifications</p>
              </div>
            </div>
          </router-link>
          <router-link
            class="nav-link"
            aria-current="page"
            :to="{ name: 'ptm' }"
          >
            <div class="notifications__inner__menu__wrapper">
              <div class="notifications__inner__menu__img">
                <img
                  src="../../assets/notification-icons/ptm.png"
                  alt="ptm"
                />
              </div>
              <div class="notifications__inner__menu__label">
                <label class="d-block"
                  >Ptm <span class="num__of__notification">{{ ptm_num.length }}</span></label>
                <p class="mb-0">Notifications</p>
              </div>
            </div>
          </router-link>
        </nav>
      </div>
    </div>
    <router-view></router-view>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "NotificationView",
  data() {
    return {
      datesheet_num: [],
      datesheet_id:1,

      syllabus_num: [],
      syllabus_id:2,

      holidays_num: [],
      holidays_id:3,

      general_num: [],
      general_id:4,

      misconduct_num: [],
      misconduct_id:5,

      sports_num: [],
      sports_id:6,

      ptm_num: [],
      ptm_id:7,
    };
  },
  mounted(){
    this.getDateSheet();
    this.getSyllabus();
    this.getHolidays();
    this.getGeneral();
    this.getMisconduct();
    this.getSports();
    this.getPtm();
  },
  methods: {
    async getDateSheet() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      const apiUrl = process.env.VUE_APP_API_URL;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          NotificationTypeId: this.datesheet_id
        };
        const response = await axios.post(
          `${apiUrl}/MobileGetStudentNotification`,
          model
        );
        this.datesheet_num = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getSyllabus() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          NotificationTypeId: this.syllabus_id
        };
        const response = await axios.post(
          `${apiUrl}/MobileGetStudentNotification`,
          model
        );
        this.syllabus_num = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getHolidays() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          NotificationTypeId: this.holidays_id
        };
        const response = await axios.post(
          `${apiUrl}/MobileGetStudentNotification`,
          model
        );
        this.holidays_num = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getGeneral() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          NotificationTypeId: this.general_id
        };
        const response = await axios.post(
          `${apiUrl}/MobileGetStudentNotification`,
          model
        );
        this.general_num = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getMisconduct() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          NotificationTypeId: this.misconduct_id
        };
        const response = await axios.post(
          `${apiUrl}/MobileGetStudentNotification`,
          model
        );
        this.misconduct_num = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getSports() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          NotificationTypeId: this.sports_id
        };
        const response = await axios.post(
          `${apiUrl}/MobileGetStudentNotification`,
          model
        );
        this.sports_num = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getPtm() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      try {
        var model = {
          UserIdentity: token,
          StudentId: studentid,
          NotificationTypeId: this.ptm_id
        };
        const response = await axios.post(
          `${apiUrl}/MobileGetStudentNotification`,
          model
        );
        this.ptm_num = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.nav-scroller {
  position: relative;
  z-index: 2;
  overflow-y: hidden;
}
.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  /* justify-content: center; */
  text-align: center;
  white-space: nowrap;
  padding-bottom: 8px;
  -webkit-overflow-scrolling: touch;
}
.notifications__inner__menu__img img {
  width: 34px;
}
.notifications__inner__menu__wrapper {
  display: flex;
  padding: 10px 13px 5px 13px !important;
  align-items: center;
}
.notifications__inner__menu__label {
  margin-left: 8px;
  text-align: left;
}
.notifications__inner__menu__label label {
  text-transform: uppercase;
  color: #000;
  font-size: 18px;
  cursor: pointer;
}
.notifications__inner__menu__label p {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  top: -4px;
}
.notifications__main__menu__wrapper .nav-link {
  padding: 0px !important;
}
.router-link-active.router-link-exact-active.nav-link {
  background: #0f75bb;
}
.router-link-active.router-link-exact-active.nav-link
  .notifications__inner__menu__label
  label {
  color: #fff;
}
.router-link-active.router-link-exact-active.nav-link
  .notifications__inner__menu__label
  p {
  color: #fff;
}
.notifications__main__menu__wrapper .nav-link:hover {
  color: unset !important;
}
.notifications__main__menu__wrapper .nav-link:hover {
  background: #0f75bb;
}
.notifications__main__menu__wrapper
  .nav-link:hover
  .notifications__inner__menu__label
  label,
.notifications__main__menu__wrapper
  .nav-link:hover
  .notifications__inner__menu__label
  p {
  color: #fff;
}
.num__of__notification {
  background: #f3ae1a;
  display: inline-block;
  padding: 7px;
  line-height: 8px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
}


/* Scrollbar Styling */
.nav-scroller .nav::-webkit-scrollbar {
    height: 5px;
}
 
.nav-scroller .nav::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.nav-scroller .nav::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(15, 117, 187, 0.5); 
}
/* Handle on hover */
.nav-scroller .nav::-webkit-scrollbar-thumb:hover {
  background: rgba(15, 117, 187, 1); 
}
</style>
