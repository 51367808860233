<template>
    <section class="login__bg__wrapper">
        <CContainer>
            <CRow class="justify-content-center align-items-center">
                <CCol lg="6">
                    <div class="login__content__header mb-4">
                        <div class="login__header__logo text-center">
                            <img src="../assets/tafs_logo.png" alt="ems-logo" class="img-fluid" />
                        </div>
                    </div>
                </CCol>
                <CCol lg="6">
                    <LoginForm />
                </CCol>
            </CRow>
        </CContainer>
    </section>
</template>
<script>
import { CCol, CContainer, CRow } from "@coreui/vue";
import LoginForm from "../components/LoginForm.vue";
export default {
  name: "LoginView",
  components: {
    LoginForm
  }
};
</script>
