<template>
    <section class="login__bg__wrapper">
        <CContainer>
            <CRow class="justify-content-center align-items-center">
                <CCol lg="4">
                    <div class="login__content__header mb-4">
                        <div class="login__header__logo text-center">
                            <img src="../assets/tafs_logo.png" alt="ems-logo" class="img-fluid" />
                        </div>
                    </div>
                </CCol>
                <CCol lg="8">
                    <SignUpForm />
                </CCol>
            </CRow>
        </CContainer>
    </section>
</template>
<script>
import { CCol, CContainer, CRow } from "@coreui/vue";
import SignUpForm from "../components/SignUpForm.vue"
export default {
    name: "LoginView",
    components: {
        SignUpForm
    }
};
</script>